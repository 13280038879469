"use client";
import React, { useEffect, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import FontAwesomeIcon from "@v2/components/FontAwesomeIcon";
import Link from "next/link";
import { useAppDispatch, useAppSelector } from "@v2/lib/redux/hooks";
import { getCartThunk } from "@/app/store/slices/cart/cartThunk";
import { updateClientCart } from "@/app/store/slices/cart/cartSlice";
import { Badge, BadgeProps, styled } from "@mui/material";
import { updateClientTryCart } from "@/app/store/slices/try-cart/tryCartSlice";

interface IProps {
    white?: boolean;
}

export default function CartButton({ white }: IProps) {
    const dispatch = useAppDispatch()
    const isAuthenticated = useAppSelector(s => s.general.session?.isAuthenticated)
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getCartThunk())
        } else {
            const cartInLocalStorage = localStorage.getItem('client_cart')
            if (cartInLocalStorage) {
                dispatch(updateClientCart(JSON.parse(cartInLocalStorage)))
            }
            const tryCartInLocalStorage = localStorage.getItem('client_try_cart')
            if (tryCartInLocalStorage) {
                dispatch(updateClientTryCart(JSON.parse(tryCartInLocalStorage)))
            }
        }

    }, [isAuthenticated]);

    const { clientCart, serverCart, loading, fetchingCart } = useAppSelector(
        (store) => store.cart
    )

    const { clientTryCart, serverTryCart } = useAppSelector(
        (store) => store.tryCart
    )

    const cartItemsCount = useMemo(() => {
        if (!isAuthenticated) {
            return clientCart?.items?.length ?? 0
        }

        return serverCart?.items?.length ?? 0
    }, [fetchingCart, isAuthenticated, serverCart, clientCart?.items])

    const tryCartItemsCount = useMemo(() => {
        if (!isAuthenticated) {
            return clientTryCart?.items?.length || 0
        }

        return serverTryCart?.items?.length ?? 0
    }, [fetchingCart, isAuthenticated, serverTryCart, clientTryCart?.items])

    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        '& .MuiBadge-badge': {
            left: 0,
            top: 4,
            border: `2px solid #fff`,
            padding: '0 4px',
            fontSize: 11
        },
    }));


    // if (true) {
    //     let itemCountsToShow = cartItemsCount
    //     if (itemCountsToShow === 0 && tryCartItemsCount > 0) {
    //         itemCountsToShow = tryCartItemsCount
    //     }
    // }

    return (
        <IconButton centerRipple sx={{ height: 42, width: 42 }} component={Link} href="/fa/cart" aria-label="نمایش سبد خرید">
            <StyledBadge badgeContent={tryCartItemsCount} color="success" anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
                <StyledBadge badgeContent={cartItemsCount} color="primary" >
                    <FontAwesomeIcon
                        icon="fa-light fa-bag-shopping"
                        color={white ? "#fff" : "#000"}
                    />
                </StyledBadge>
            </StyledBadge>
        </IconButton>
    );
}


