const api = {
	check_pathname: process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v1/check_pathname",
	check_pathname_internal: process.env.NEXT_PUBLIC_APP_URL + "/api/cache/pathname",
	homepage: process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v2/homepage",
	menu: process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v2/menu",
	plp: process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v2/product/site",
	plp_data_only: process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v2/product/site/data_only",
	search: process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v2/search",
	media: process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v1/media",

	get_otp: process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v1/auth/getOtp",
	confirm_otp: process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v1/auth/confirmOtp",
	logout: process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v1/auth/logout",
	profile: process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v1/user/profile",
    

    favorite: process.env.NEXT_PUBLIC_BASE_API_URL + "/api/v1/user/favorite",
};

export default api;
