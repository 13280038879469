import moment from "jalali-moment";
// import { setDialogTo, setDrawerTo, setPageLoading } from "redux/slice";
// import store from "redux/store";
// import { SetDialogPayload, SetDrawerPayload } from "redux/types";

export const toJalaliDate = (datetime?: string | null | Date, format = "HH:mm:ss YYYY-MM-DD") => {
	if (!datetime) return null;
	if (datetime instanceof Date) {
		return moment(datetime).locale("fa").format(format);
	}
	return moment.from(datetime, "en", "YYYY/MM/DD HH:mm:ss").locale("fa").format(format);
};

export const clone = (input: object) => {
	return JSON.parse(JSON.stringify(input));
};

export const mobileFormatter = (value: string | null) => {
	let output;
	output = p2e(value || "");
	output = output?.replace(/[^0-9]/g, "");
	output = output.replace(/^\+98|^98|^098|^0098/, "");

	if (!output.startsWith("0")) {
		output = output ? "0" + output : output;
	}

	if (!output.startsWith("9", 1) && output.length > 1) {
		output = output.replace(/^0/, "");
		output = output ? "09" + output : output;
	}

	return output;
};

export const getSize = (bytes: number, decimals: number = 2) => {
	if (!+bytes) return "0 Bytes";

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// export const setPageLoadingTo = (status: boolean | string) => {
//     store.dispatch(setPageLoading(status));
// };

export const humanFileSize = (size: number) => {
	if (size < 1024) return size + " B";
	let i = Math.floor(Math.log(size) / Math.log(1024));
	let num: any = size / Math.pow(1024, i);
	let round = Math.round(num);
	num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
	return `${num} ${"KMGTPEZY"[i - 1]}B`;
};

export const pHumanFileSize = (size: number) => {
	if (size < 1024) return size + " بایت";
	let i = Math.floor(Math.log(size) / Math.log(1024));
	let num: any = size / Math.pow(1024, i);
	let round = Math.round(num);
	num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
	let unit = ["کیلو", "مگا", "گیگا", "ترا", "پتا"];
	return `${num} ${unit[i - 1]}بایت`;
};

export const e2p = (s: any) => s.toString().replace(/\d/g, (d: any) => "۰۱۲۳۴۵۶۷۸۹"[d]);
export const p2e = (s: any) => s.toString().replace(/[۰-۹]/g, (d: any) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d));

export const randomInt = (min: number, max: number): number => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const numberFormat = (value?: string | number | null, count: number = 3, separator: string = ",") => {
	if (typeof value === "undefined" || value === null) return value;

	if (typeof value === "number") value = String(value);
	let objRegex = new RegExp("(-?[0-9]+)([0-9]{" + count + "})");
	let val = value.replace(/[^0-9-]/gi, "");
	while (objRegex.test(val)) {
		val = val.replace(objRegex, "$1" + separator + "$2");
	}
	return val;
};

export const sum = (array: any[], selector: string) => {
	if (typeof selector === "function") {
		return array.reduce(selector, 0);
	} else {
		switch (typeof array[0]) {
			case "object":
				return (
					array
						.map((item) => {
							let splitSelector = selector.split(".");
							if (splitSelector.length > 1) {
								let output = item;
								try {
									splitSelector.forEach((key, index) => {
										if (Array.isArray(output)) {
											let nSelector = splitSelector.slice(index).join(".");
											// output = output.sum(nSelector)
											output = sum(output, nSelector);
											throw new Error();
										} else {
											output = output[key];
										}
									});
								} catch (error) {
									//
								}
								return typeof output === "number" ? output : 0;
							} else {
								return item[selector] || 0;
							}
						})
						.reduce((a, b) => a + b, 0) || 0
				);
			case "number":
				return array.reduce((a, b) => a + b, 0);
			default:
				return 0;
		}
	}
};

export const mask = (value: string, pattern: string, placeholder = "") => {
	let i = 0,
		v = value.toString();
	return pattern.replace(/\./g, (_) => v[i++] || placeholder);
};

export const parseQuery = (queryString: string) => {
	let query: any = {};
	let pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");
	for (let i = 0; i < pairs.length; i++) {
		let pair = pairs[i].split("=");
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
	}
	return query;
};

export const encode = (data: string) => {
	return Buffer.from(data).toString("base64");
};
export const decode = (data: string) => {
	return Buffer.from(data, "base64").toString("ascii");
};

// export const drawer = (config: SetDrawerPayload) => {
//     config.body = config.body ? config.body : null;
//     config.position = config.position ? config.position : "bottom";

//     store.dispatch(setDrawerTo(config));
// };

// export const dialog = (config: SetDialogPayload) => {
//     store.dispatch(setDialogTo(config));
// };

const empty = Symbol("empty");

export const removeEmpties = (t: any) => {
	switch (t?.constructor) {
		case Array:
		case Object:
			return filter(map(t, removeEmpties), nonEmpty);
		default:
			return nonEmpty(t) ? t : empty;
	}
};

const nonEmpty = (t: any) => {
	switch (t?.constructor) {
		case Array:
			return t.length > 0;
		case Object:
			return Object.keys(t).length > 0;
		//case String:             // <- define any empty types you want
		//  return t.length > 0
		default:
			return t !== empty; // <- all other t are OK, except for sentinel
	}
};

const map = (t: any, f: any) => {
	switch (t?.constructor) {
		case Array:
			return t.map(f);
		case Object:
			return Object.fromEntries(Object.entries(t).map(([k, v]) => [k, f(v, k)]));
		default:
			return t;
	}
};

const filter = (t: any, f: any) => {
	switch (t?.constructor) {
		case Array:
			return t.filter(f);
		case Object:
			return Object.fromEntries(Object.entries(t).filter(([k, v]) => f(v, k)));
		default:
			return t;
	}
};

export const cleanEmpty: any = (obj: any, defaults = [undefined, null, NaN, "", '""', false]) => {
	if (defaults.includes(obj)) return;

	if (Array.isArray(obj)) return obj.map((v) => (v && typeof v === "object" ? cleanEmpty(v, defaults) : v)).filter((v) => !defaults.includes(v));

	return Object.entries(obj).length
		? Object.entries(obj)
				.map(([k, v]) => [k, v && typeof v === "object" ? cleanEmpty(v, defaults) : v])
				.reduce((a, [k, v]) => (defaults.includes(v) ? a : { ...a, [k]: v }), {})
		: obj;
};

export const countDecimals = (number: number) => {
	if (Math.floor(number.valueOf()) === number.valueOf()) return 0;
	return number.toString().split(".")[1].length || 0;
};

export const naiveRound = (num: number, decimalPlaces = 0) => {
	var p = Math.pow(10, decimalPlaces);
	return Math.round(num * p) / p;
};

export const range = (start: number, end: number, step = 1) => {
	const decimals = countDecimals(step);
	const len = Math.floor((end - start) / step) + 1;
	return Array(len)
		.fill(1)
		.map((_, idx) => {
			return naiveRound(start + idx * step, decimals);
		});
};

export const splittedTitle = (title: string): string[] => {
	const splitTitle = title.split("-");
	const mainTitle = splitTitle.shift().trim();
	const remainTitle = splitTitle.join(" ").trim();
	return [mainTitle, remainTitle];
};

export const uniqueByKey = <T>(array: T[], key: string): T[] => {
	return [...new Map(array.map((item) => [item[key], item])).values()];
};
