"use client"

import { Box, BoxProps } from "@mui/material";
import api from "@v2/lib/api";
import Image, { ImageProps } from "next/image";
import fallBackSrc from "~/public/assets/media-placeholder.webp";


const MediaV2 = (props: MediaV2Props) => {
    const params = new URLSearchParams();
    const action = props.options?.action ?? "cropThumbnail";
    params.set("action", action.toString());

    if (props.height) params.set("height", props.height.toString());
    if (props.width) params.set("width", props.width.toString());
    if (props.options?.opacity) params.set("opacity", props.options?.opacity.toString());
    if (props.options?.quality) params.set("quality", props.options?.quality.toString());
    if (props.options?.background) params.set("background", props.options?.background.toString());
    if (props.options?.fill) params.set("fill", '1');
    if (props.options?.bestFill) params.set("bestFill", '1');
    const stringifyParams = params.toString();
    const src = api.media + "/" + props.media_id + (stringifyParams ? "?" + stringifyParams : "");

    return (
        <Box {...props.boxProps} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            position: 'relative',

            ...props.boxProps?.sx
        }}>
            <Image
                height={!props.imageProps?.fill ? props.height : undefined}
                width={!props.imageProps?.fill ? props.width : undefined}
                src={src}
                alt={props.alt ?? ""}
                title={props.title ?? ""}
                onError={!props.disablePlaceholder ? (e) => (e.currentTarget.src = fallBackSrc.src) : undefined}
                placeholder={!props.disablePlaceholder ? "blur" : undefined}
                blurDataURL={!props.disablePlaceholder ? fallBackSrc.src : undefined}
                unoptimized

                {...props.imageProps}
            />
        </Box>
    )
};

export interface MediaV2Props {
    /**
     * server side id
     */
    media_id: null | string;

    boxProps?: BoxProps,
    imageProps?: Omit<ImageProps, 'src' | 'alt'>,
    height?: number,
    width?: number,
    alt?: string,
    title?: string,

    disablePlaceholder?: boolean,

    options?: {
        /**
         * @default `cropThumbnail`
         */
        action?: "thumbnail" | "cropThumbnail";

        fill?: boolean;
        bestFill?: boolean;

        /**
         * @value color like `rgb(0,0,0)`
         */
        background?: string;

        /**
         * @value `0` to `1`
         */
        opacity?: number;

        /**
         * @value `1` to `100`
         * @default 92
         */
        quality?: number;

        isGlass?: boolean,

    }
}

export default MediaV2
