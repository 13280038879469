"use client";
import { Box, colors, Divider, Drawer, Fade, InputBase, Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FontAwesomeIcon from "@v2/components/FontAwesomeIcon";
import { p2e } from "@v2/helpers/general";
import useTouchable from "@v2/hooks/useTouchable";
import api from "@v2/lib/api";
import axios, { AxiosResponse } from "@v2/lib/axios";
import Link from "next/link";
import { ChangeEvent, Fragment, KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import MediaV2 from "../MediaV2";
import CenterBox from "../styled/CenterBox";
import { SearchBrandItem, SearchProductItem } from "./types";

interface IProps {
    white?: boolean;
}

export default function SearchButton({ white }: IProps) {
    const theme = useTheme()
    const timeout = useRef(null)
    const isTouchable = useTouchable()


    const [query, setQuery] = useState('');
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const [products, setProducts] = useState<SearchProductItem[]>([])
    const [brands, setBrands] = useState<SearchBrandItem[]>([])


    const closeDrawer = useCallback((e, reason: string) => {
        // if (reason === "backdropClick") return;
        setOpen(false)
        setProducts([])
        setBrands([])
    }, [])

    const openDrawer = useCallback(() => {
        setOpen(true)
    }, [])

    const onChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setQuery(p2e(e.target.value))
    }, [])

    const onKeyUpInput = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            window.open('/fa/products/?search=' + encodeURI(query), '_ blank');
        }
    }, [query])

    const search = useCallback(() => {
        if (!query) return;
        setLoading(true)

        axios.get(api.search + "?query=" + query)
            .then((res: AxiosResponse<{
                brands: SearchBrandItem[],
                product: {
                    hits: SearchProductItem[]
                }
            }>) => {
                setBrands(res.data.data.brands)
                setProducts(res.data.data.product.hits)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [query])

    useEffect(() => {
        clearTimeout(timeout.current)

        timeout.current = setTimeout(() => {
            search()
        }, 600)

    }, [query])

    return (
        <Fragment>
            <IconButton sx={{ height: 42, width: 42 }} onClick={openDrawer} aria-label="جستجو در محصولات سایت">
                <FontAwesomeIcon
                    icon="fa-light fa-magnifying-glass"
                    color={white ? "#fff" : "#000"}
                />
            </IconButton>
            <Drawer
                ModalProps={{
                    keepMounted: false
                }}
                anchor={'top'}
                open={open}
                onClose={closeDrawer}
                disableScrollLock={!isTouchable}
                PaperProps={{
                    sx: {
                        boxShadow: 'unset',
                        [theme.breakpoints.up('sm')]: {
                            mt: '88px',
                            width: 640,
                            mx: 'auto',
                            backgroundColor: 'transparent'
                        }
                    }
                }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: '#fff',
                    borderRadius: 1,
                    height: 56,
                    px: 1
                }}>
                    <IconButton sx={{
                        height: 40, width: 40
                    }} loading={loading} onClick={search}>
                        {(!loading) && (
                            <Fade in={true}>
                                <CenterBox>
                                    <FontAwesomeIcon icon="fa-light fa-arrow-right" size={24} />
                                </CenterBox>
                            </Fade>
                        )}
                    </IconButton>
                    <Box sx={{
                        flex: 1, pl: 1,
                        // bgcolor: 'silver',
                    }}>
                        <InputBase
                            slotProps={{
                                input: {
                                    autoFocus: true,
                                    dir: 'auto',
                                    sx: {
                                        '&::placeholder': {
                                            textAlign: 'left',
                                            color: colors.grey[900]
                                        }
                                    }
                                }
                            }}
                            onKeyUp={onKeyUpInput}
                            onChange={onChangeInput}
                            sx={{ height: 40 }}
                            fullWidth
                            placeholder="... جستجو در محصولات، برندها و" />
                    </Box>
                    <IconButton sx={{
                        height: 40, width: 40
                    }} onClick={(e) => closeDrawer(e, 'closeButton')}>
                        <FontAwesomeIcon icon="fa-light fa-xmark" size={24} />
                    </IconButton>
                </Box>
                {(!!products.length || !!brands.length) && (
                    <Box sx={{
                        mt: 2,
                        borderRadius: 1,
                        bgcolor: '#fff',
                        p: 2,
                        pb: 0,
                        maxHeight: '80vh',
                        overflowY: 'auto',
                        [theme.breakpoints.down('sm')]: {
                            maxHeight: 'calc(100vh - 72px)',
                        }
                    }}>
                        {!!(brands.length) && (
                            <Fragment>
                                <Typography sx={{ mb: 1, color: colors.grey[600] }}>برند ها</Typography>
                            </Fragment>
                        )}

                        {brands.map((brand) => (
                            <SearchedBrand data={brand} key={brand.id} />
                        ))}

                        {!!(brands.length) && (
                            <Fragment>
                                <Divider sx={{ mb: 2 }} />
                            </Fragment>
                        )}

                        {!!(products.length) && (
                            <Fragment>
                                <Typography sx={{ mb: 1, color: colors.grey[600] }}>محصولات</Typography>
                            </Fragment>
                        )}

                        {products.map((sp) => (
                            <SearchedProduct data={sp} key={sp.document.id} />
                        ))}
                    </Box>
                )}
            </Drawer>
        </Fragment>
    );
}

const SearchedProduct = ({ data }: { data: SearchProductItem }) => {
    const splitTitle = data.document.site_product.title.split('-')
    const t1 = splitTitle[0]?.trim()
    return (
        <Box component={Link} href={`/fa/products/${data.document.site_product.slug}?color_code=${data.document.color_code}`} prefetch={false}
            aria-label={data.document.site_product.title}
            target="_blank" sx={{
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                    '& .search_item_image': {
                        borderColor: 'transparent',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                    },
                    '& .search_item_main_title': {
                        color: colors.grey[900]
                    }
                }
            }}>
            <MediaV2 media_id={data.document.image} alt={data.document.name} height={56} width={70} boxProps={{
                className: 'search_item_image',
                sx: {
                    transition: '250ms',
                    width: 78,
                    height: 56,
                    border: '1px solid',
                    borderColor: colors.grey[300],
                    borderRadius: 1,
                }
            }} />

            <Box sx={{
                ml: 2
            }}>
                <Typography className="search_item_main_title" sx={{ transition: '200ms', fontSize: 16, color: colors.grey[700] }}>{t1}</Typography>
                <Typography className="en-num" sx={{ fontSize: 13, mt: 0.5, letterSpacing: 0.5, color: colors.grey[600] }}>{data.document.name}</Typography>
            </Box>
        </Box>
    )
}

const SearchedBrand = ({ data }: { data: SearchBrandItem }) => {
    return (
        <Fade in={true}>
            <Box component={Link} href={`/fa/brand/${data.slug}`} target="_blank" prefetch={false} sx={{
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                    '& .search_item_image': {
                        borderColor: 'transparent',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                    },
                    '& .search_item_main_title': {
                        color: colors.grey[900]
                    }
                }
            }}>
                <MediaV2 media_id={data?.logo?.id} alt={data.name} height={56} width={70} boxProps={{
                    className: 'search_item_image',
                    sx: {
                        transition: '250ms',
                        width: 78,
                        height: 56,
                        border: '1px solid',
                        borderColor: colors.grey[300],
                        borderRadius: 1,
                    }
                }} />

                <Box sx={{
                    ml: 2
                }}>
                    <Typography className="search_item_main_title" sx={{ transition: '200ms', fontSize: 16, color: colors.grey[700] }}>{data.name}</Typography>
                </Box>
            </Box>
        </Fade>
    )
}
