"use client";
import throttle from "lodash.throttle";
import { DependencyList, useEffect } from "react";

export interface Configs {
	ttl?: number;
}
export default function useScrollYChange(callback: (scrollYPosition: number) => void, deps?: DependencyList, configs?: Configs): void {
	const onScrollEvent = throttle(() => {
		const { scrollY: clientScrollY } = window;
		callback(clientScrollY);
	}, configs?.ttl ?? 300);

	useEffect(() => {
		window.removeEventListener("scroll", onScrollEvent);
		window.addEventListener("scroll", onScrollEvent);
		return () => {
			window.removeEventListener("scroll", onScrollEvent);
		};
	}, [deps]);
}
