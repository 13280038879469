import baseAxios, { AxiosError as BaseAxiosError, AxiosResponse as BaseAxiosResponse } from "axios";

const axios = baseAxios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": "fa",
    },
});
axios.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem("accessToken");
        if (token) config.headers["Authorization"] = "Bearer " + token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


export type AxiosResponse<Data = any> = BaseAxiosResponse<{
    key: string,
    message: string,
    data: Data
}>

export type AxiosError<Data = any> = BaseAxiosError<{
    error: string,
    message: string,
    data: Data
}>


export default axios;
